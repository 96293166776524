import * as DateTimeFormatUtils from '../../common/DateTimeFormatUtils';
import { ValueFormatterParams } from '@ag-grid-enterprise/all-modules';

const DateFormatter = ({
    node,
    value,
    context,
}: ValueFormatterParams): string => {
    if (!value || (node && node.group)) {
        return '';
    }
    return context
        ? DateTimeFormatUtils.formatDate(value, context.userTimezone)
        : DateTimeFormatUtils.formatDefaultDate(value);
};

export default DateFormatter;
